@import "styles/_variables.scss";

.container{
    // display:inline-block;
    display: flex;
    padding-left: 8px;
    .flag{
        width: 16px;
    }
    @media only screen and (max-width: 576px){
        .flag{
            margin-left: 8px;
        }
        .mobile_lang_title{

            margin-left: 2px;
            color: #3f51b5 !important;
        }
    }
    ul{
        width: 180px;
        height: 97px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
    }
    .lang_title{
        font-size: 14px;
        font-weight: 500;
    }
}