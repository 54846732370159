@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.wrapper {
    display       : flex;
    flex-direction: column;
    overflow-y    : auto;
    overflow-x    : hidden;
}

.content {
    margin          : 1em;
    padding         : 1em;
    margin-top      : 2em;
    background-color: $white;
    border-radius   : 4px;
    box-sizing      : border-box;
    box-shadow      : 0 1px 3px rgba(0, 0, 0, 0.12);
}

.counter {
    padding-top    : 0.5em;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
}

.menu_link {
    cursor: pointer;

    align-items          : center;
    grid-gap             : 1em;
    justify-content      : space-between;
    grid-template-columns: max-content min-content;
    grid-template-rows   : min-content;
    display              : grid;
}

.toolbar {
    display       : flex;
    flex-direction: row;
    margin        : -0.5em;
    flex-wrap     : wrap;
    box-sizing    : border-box;

    &>* {
        margin: 0.5em;
    }


    .search {
        flex-basis : 300px;
        flex-grow  : 0.3;
        flex-shrink: 0.3;

        @include media("<md") {
            flex-grow  : 0.7;
            flex-shrink: 0.7;
        }
    }
}

.btn_filled {
    background-color: $pe_primary !important;
}

.btn_nothing {
    @include media("<md") {
        display: none;
    }
}