.container {
    padding: 10px;

    .status {
        font-family: "Red Hat Text";
        font-size  : 14px;
        font-weight: 400;
        line-height: 24px;

        .open {
            color: #51a548;
        }
    }
}

.bottomComponent {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    justify-items : center;
    flex-wrap     : wrap;

    &>* {
        margin-right: 1em;
    }

    &>input {
        width: 250px;
    }
}

.loader {
    grid-column    : 1/-1;
    display        : flex;
    align-items    : center;
    justify-content: center;
}