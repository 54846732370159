@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.wrapper {
    // @include media(">sm") {
    //     width    : 70% !important;
    //     max-width: var(--pf-c-modal-box--MaxWidth) !important;
    // }


}

.sub_title {
    padding    : 0 1.5rem;
    position   : relative;
    top        : -0.5rem;
    font-size  : 14px;
    font-weight: 500;
}


.modal_root {
    &>div {
        padding: 1em 0 0 0;
    }

    @include media(">md") {
        width    : 90% !important;
        max-width: var(--pf-c-modal-box--MaxWidth) !important;
    }

    @include media(">lg") {
        width    : 70% !important;
        max-width: var(--pf-c-modal-box--MaxWidth) !important;
    }

    @include media("<md") {
        width: 100% !important;
    }
}


.btn_new {
    color: #8481dd !important;
}

.table_container {}

.table_header {
    display              : grid;
    gap                  : 1em;
    margin-bottom        : 0.5em;
    justify-items        : flex-start;
    grid-template-columns: repeat(auto-fit, 250px);
}

.input_search {
    width: 100%;
}

.btn_new_dep {
    justify-self: flex-start;
}


.card__table {
    // min-height: 238px;
}


.card__title {
    font-size: 14px;
}

.card__main_text {
    font-size: 21px;
}

.card {
    // box-shadow         : 0 1px 3px rgba(0, 0, 0, 0.12);
    background-color   : #ffffff;
    // padding         : 0.7em;
}

.container {}

.card__fullwidth {
    grid-column: 1/-1;
}

.card__halfwidth {
    grid-column: span 2;
    grid-row   : span 2;
}


.title {
    font-weight: bold;
}

.grid_column {
    display       : grid;
    row-gap       : 1rem;
    grid-auto-flow: row;
    margin-bottom : 1rem;
}

.message {}

.flex_col {
    display       : flex;
    flex-direction: column;
}

.header {
    margin        : -0.5rem;
    padding       : 0.5rem 1.5em;
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;

    &>* {
        margin: 0.5rem;
    }
}

.search_field {
    flex-basis : 250px;
    flex-grow  : 0.2;
    flex-shrink: 0.2;
}

.link {
    color: #8481dd !important;
    cursor: pointer;
}

.empty_list {
    padding-left: 1rem;
}