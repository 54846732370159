@import "styles/_variables.scss";

.notification_container {
    // min-height              : 26px;
    // position                : absolute;
    // top                     : 0px;
    width                      : calc(100% + 2em);
    transform                  : translateX(-1em);
    // left                    : 0px;
    margin-bottom              : 1em;
    box-sizing                 : border-box;
    padding                    : 0px 0.5em;
    height: 25px;
    font-size: 14px;
    display                    : grid;
    column-gap                 : 0.5em;
    grid-template-rows         : min-content;
    justify-items              : center;
    text-align                 : center;
    align-items                : center;
    align-content              : center;
    color                      : white;
    grid-template-columns      : 1fr 30px;
    background-color           : $pe_primary;
}

.notification_title {
    // font-weight: bold;
    position      : relative;
}

.sub_title {
    position: relative;
}

.btn_close {
    grid-column     : 2;
    margin          : 4px 0px;
    display         : flex;
    align-self      : stretch;
    justify-self    : stretch;
    align-content   : center;
    height          : 30px;
    align-self      : center;
    width           : 100%;
    align-items     : center;
    border          : unset;
    justify-content : center;
    box-shadow      : unset;
    background-color: transparent;

    &:hover {
        background-color: rgba(255, 255, 255, 0.356);
        border-radius   : 8px;
    }

    position  : relative;
    color     : white;
    transition: all 0.3s ease-in-out;
}

.btn {
    border          : unset;
    background-color: transparent;
    font-weight     : bold;
    box-shadow      : unset;
    min-height      : 25px;
    box-sizing      : border-box;
    padding         : 0.3em 0.7em;
    border-radius   : 0.3em;
    color           : white;
    max-width       : 150px;
}

.actions {
    display              : grid;
    grid-template-columns: 1fr 1fr;
    justify-content      : center;
    align-items          : center;
    position             : relative;
    grid-gap             : 8px;
    margin-top           : 0.5em;
}

.btn_negative {
    background-color: map-get($map: $pe_warning, $key: error);
}

.btn_positive {
    background-color: map-get($map: $pe_warning, $key: success);
}


.bg_error {
    background-color: map-get($map: $pe_warning, $key: error);
}

.bg_warning {
    background-color: map-get($map: $pe_warning, $key: warning);
}

.bg_default {
    background-color: map-get($map: $pe_warning, $key: default);
}

.bg_success {
    background-color: map-get($map: $pe_warning, $key: success);
}