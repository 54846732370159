@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities
@import "~include-media/dist/_include-media.scss";

.wrapper {
  display: grid;
  align-items: flex-start;
  min-height: 100vh;
  justify-items: center;
  padding: 2em 0;
  width: 100%;
  background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.541),
      rgba(0, 0, 0, 0.541)
    ),
    // rgba(45, 194, 157, 0.541),
    // rgba(45, 194, 157, 0.541)
    url("./../../assets/imgs/background.svg");
  background-size: cover, cover;
  background-repeat: no-repeat no-repeat;
}

.container {
  max-width: 1000px;
  width: 100%;
  margin: 0em auto;
  position: relative;
  padding: 1em;
  display: grid;
  font-size: 14px;
  grid-auto-flow: row;
  row-gap: 0.5em;
  background-color: $white;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -1.5em;

  & > * {
    flex-basis: 250px;
    flex-grow: 1;
    padding: 1.5em;
    margin-bottom: 1em;
    flex-shrink: 1;
    align-self: flex-start;
  }

  @include media("<sm") {
    margin: 0;

    & > * {
      padding: 0;
    }
  }
}

.first_section {
  display: grid;
  grid-auto-flow: row;
  align-content: space-between;
  flex-basis: 60%;
}

.form {
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
}

.title {
  color: #151515;
  font-family: "Red Hat Text";
  font-size: 18px;
  font-weight: 500;
  line-height: 31.2px;
}

.row {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 0px;
  @include media(">sm") {
    grid-auto-flow: row;
    grid-template-columns: unset;
  }
}
.verifiable_section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.count_input {
  width: 50px;
  margin-left: 1.5rem;
}

.formfield {
  display: grid;
  grid-auto-flow: row;

  & > span {
    text-align: left;
    justify-self: flex-start;
  }
}

.fullWidth {
  grid-column: 1/-1;
}

.btn_submit {
  width: 50%;
  justify-self: center;
}

.btn_submit {
  margin-top: 1em;
  background-color: $pe_primary;
}

.second_section {
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
}

.requested_infos {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5em;
  justify-items: flex-start;
  margin-bottom: 1em;
}

.file_field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  height: 55px;

  & > * {
    flex-basis: 180px;
  }
}

.btn_delete {
  margin-left: 1em;
}

.text_muted {
  color: rgba(51, 51, 51, 0.527);
}

.cover {
  display: flex;
  flex-grow: 0.5;
  flex-shrink: 0.5;
  align-items: center;
}

.input_file {
  display: none;
}

.c_error {
  color: map-get($map: $pe_theme, $key: text_error);
}

.logo {
  height: 50px;
}

.empty_state {
  background-color: rgba(90, 215, 169, 0.541);
  margin: 1em auto;
  border-radius: 0.5em;
  & > * {
    color: white !important;
  }
}

.empty_state--error {
  background-color: rgba(230, 38, 49, 0.541);
  margin: 1em auto;
  border-radius: 0.5em;
  & > * {
    color: white !important;
  }
}

.header {
  font-size: 21px;
  font-weight: 400;
  max-width: 500px;
}

.language{
  @include media("<sm") {
      border: 1px solid #5652d1;
      width: 93px;
  }
}
.language div{
  border: 1px solid #5652d1;
  padding-left: unset;
  @include media("<sm") {
    border: unset;
  }
}