@import "styles/_variables.scss";


.pageHeader {
    height    : 57px;
    min-height: 40px;

    .logoHeaderLeft {
        max-height: 30px;
    }
}

.sideBar {}

.sideBarNav {
    width: inherit;
}

.pageBody {}

.sessionNavSelect {
    position: relative;
    display : inline-block;
    width   : 100%;
    padding : 0;

    &>button {
        margin-left: 0;
        padding-left: 40px;
        font-weight: bold;
    }

    .customRoleDropdown {
        // width           : 200px;
        right           : 0;
        left            : 1em;
        z-index         : 1;
        // position        : absolute;
        background-color: #FFFFFF;
        color           : rgba(40, 40, 40, 0.88);
        padding         : 1rem 0;
        max-height      : 100px;
        overflow-y      : auto;

        ul {
            padding        : 0 0.6rem;
            list-style-type: none !important;
            color          : inherit;

            li {
                cursor: pointer;

                &:active {
                    color: #2b9af3;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #d3d3d3;
                }
            }
        }
    }

    &::after {
        font-family: "Font Awesome 5 Free";
        content    : ' \f007';
        position   : absolute;
        left       : 18px;
        color:white;
        top        : 14px;
    }
}


.menu_item {
    color: $white !important;

    &>* {
        color: $white !important;
    }
}

.role_selector {
    display        : flex;
    justify-items  : center;
    justify-content: space-evenly;
    align-items    : center;
    width          : 100%;
    justify-self   : stretch;
}
