@import "styles/_variables.scss";

.wrapper {
    display       : flex;
    flex-direction: column;
    overflow-y    : auto;
    overflow-x    : hidden;
}

.content {
    margin          : 1em;
    padding         : 1em;
    margin-top      : 2em;
    background-color: $white;
    border-radius   : 4px;
    box-sizing      : border-box;
    box-shadow      : 0 1px 3px rgba(0, 0, 0, 0.12);
}

.counter {
    padding-top    : 0.5em;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
}

.link {
    color: $pe_primary;

    &>* {
        color: $pe_primary;
    }

    align-items          : center;
    grid-gap             : 1em;
    justify-content      : space-between;
    grid-template-columns: max-content min-content;
    grid-template-rows   : min-content;
    display              : grid;
}

.toolbar {
    display       : flex;
    flex-direction: row;
    margin        : -0.5em;
    flex-wrap     : wrap;
    box-sizing    : border-box;
    align-items   : center;

    &>* {
        margin: 0.5em;
    }

    .search {
        flex-basis: 450px;
    }

    .dropdown {
        margin: 0.7em;

        .kebabtoggle {
            color           : rgb(115, 118, 121);
            background-color: unset;
        }
    }
}

.btn_filled {
    background-color: $pe_primary !important;
    height          : 36px;
}

.modal {
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
    gap           : 1rem;
}

.section_title {
    font-weight: bold;
    margin     : 8px 0 !important;
}

.questionList {
    display       : flex;
    flex-direction: column;
    gap           : 8px;
    list-style-type: disc;
    padding-left: 1rem;
}