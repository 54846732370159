@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.container {
    // @include media(">sm") {
    //     width    : 70% !important;
    //     max-width: var(--pf-c-modal-box--MaxWidth) !important;
    // }

    display              : grid;
    padding              : 1rem;
    gap                  : 1rem;
    box-sizing           : border-box;
    grid-template-columns: repeat(auto-fit, minmax(273px, 1fr));
    align-items          : flex-start;
    align-content        : flex-start;

    color      : #151515;
    font-family: "Red Hat Text";
    font-size  : 14px;
    font-weight: 400;

}

.item {
    box-shadow           : 0 1px 3px rgba(0, 0, 0, 0.12);
    background-color     : #ffffff;
    padding              : 12px;
    display              : grid;
    grid-template-columns: min-content 1fr min-content;
    gap                  : 8px;
    justify-items        : flex-start;
    align-items          : flex-start;
    text-align           : left;
}

.item__price{
    grid-row: 2;
    grid-column: 2/-1;
}

.loader {
    grid-column    : 1/-1;
    height         : 70px;
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.checkbox {
    position: relative;
    top     : 6px;
}

.top {
    margin-top           : 8px;
    justify-self         : flex-start;
    display              : grid;
    grid-template-columns: min-content min-content;
    gap                  : 12px;
}

.field {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    margin-bottom: 12px;
    &:first-child {
        margin-top: 16px;
    }

    &:last-child {
        margin-bottom: 24px;
    }
}

.title {
    display        : flex;
    text-align     : left;
    justify-content: flex-start;
    align-items    : center;
}

.warning {
    color: red;
}