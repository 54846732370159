@import "styles/_variables.scss";

.container {
    .modal_content {
        min-height: 500px !important;
    }
}

.btn_primary {
    background-color: map-get($map: $pe_theme, $key: primary) !important;
}

.color_primary {
    color: map-get($map: $pe_theme, $key: text_primary) !important;
}

.content {
    min-height: 450px;
}

.theme {
    padding              : 20px;
    display              : grid;
    grid-template-columns: 1fr 1fr;
    grid-gap             : 15px;
}

.parent_info {
    display        : grid;
    grid-auto-flow : row;
    row-gap        : 8px;
    align-items    : center;
    justify-content: flex-start;
}

.row {}