@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.wrapper {
    display                : grid;
    align-items            : flex-start;
    // justify-content     : center;
    justify-items          : center;
    padding                : 2em 0;
    width                  : 100%;
    height                 : 100%;
    align-content          : flex-start;
    background-image       : linear-gradient(to top, rgba(0, 0, 0, 0.241), rgba(0, 0, 0, 0.241)),
        url("./../../../assets/imgs/reset_password_bg.png");
    background-size  : cover, cover;
    background-repeat: no-repeat no-repeat;
}

.container {
    max-width: 644px;
    width    : 100%;
    margin   : 0em auto;
    position : relative;
    padding  : 1em;
}

.content {
    padding       : 1.5em;
    line-height   : 1.5;
    display       : flex;
    margin-top    : 0em;
    flex-direction: column;
    background    : white;

    &>* {
        margin-bottom: 0.5em;
    }
}

.title {
    font-size: 1.5em;
}

.c_error {
    color: map-get($map: $pe_theme, $key: text_error);
}

.field {
    display  : flex;
    flex-wrap: wrap;
}

.btn {
    padding         : 0.25em 1em;
    height          : 37px;
    border-radius   : 3px;
    color           : $pe_primary;
    box-shadow      : unset;
    border          : unset;
    background-color: transparent;

    &:disabled {
        background-color: #aaa;
        color           : #333;
    }

    &:hover {
        background-color: $pe_primary;
        color           : map-get($map: $pe_theme, $key: white);
    }
}

.btn_primary {
    background-color: $pe_primary;
    color           : map-get($map: $pe_theme, $key: white);
}

.checkbox_box {
    height         : 100%;
    display        : flex;
    justify-content: flex-start;
    align-items    : flex-end;
}

.actions {
    margin-top: 1em;
}

.date_input {
    width: 100%;
}

.input_field {
    align-content: flex-start;
}

.header {
    margin-bottom: 1em;
}

.logo {
    height: 70px;
    width: 120px;
}

.empty_state {
    margin: 1em auto;
}