@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.wrapper {
    display       : flex;
    flex-direction: column;
    overflow-y    : auto;
    overflow-x    : hidden;
}

.content {
    margin          : 1em;
    padding         : 1em;
    margin-top      : 2em;
    background-color: $white;
    border-radius   : 4px;
    box-sizing      : border-box;
    box-shadow      : 0 1px 3px rgba(0, 0, 0, 0.12);
}

.counter {
    padding-top    : 0.5em;
    display        : flex;
    flex-direction : row;
    justify-content: flex-end;
    align-items    : center;
    justify-content: space-between;
}

.link {
    color : $pe_primary;
    cursor: pointer;

    &>* {
        color: $pe_primary;
    }

    align-items          : center;
    grid-gap             : 1em;
    justify-content      : space-between;
    grid-template-columns: max-content min-content;
    grid-template-rows   : min-content;
    display              : grid;
}

.toolbar {
    display       : flex;
    flex-direction: row;
    margin        : -0.5em;
    flex-wrap     : wrap;
    box-sizing    : border-box;

    &>* {
        margin: 0.5em;
    }

    .search {
        flex-basis: 300px;
        flex-grow: 0.3;
        flex-shrink: 0.3;
        @include media("<md"){
            flex-grow: 0.7;
            flex-shrink: 0.7;
            }
    }
}

.btn_filled {
    background-color: $pe_primary !important;
}

.status_box {
    display        : grid;
    grid-auto-flow: column;
    font-size      : 14px;
    font-weight    : 400;
    column-gap: 4px;
    justify-content: flex-start;
    align-items    : center;
}

.archived {
    color: map-get($map: $pe_theme, $key: text_warning);
}

.open {
    color: map-get($map: $pe_theme, $key: text_disabled);
}

.locked, .archived_and_closed {
    color: map-get($map: $pe_theme, $key: text_success);
}

.loading {
    color: $pe_primary;
}

.btn_nothing{
    @include media("<md"){
        display: none;
    }
}

.number{
    background-color: $pe_primary;
    margin-right: 5px;
    border-radius: 5px;
    padding: 0.5px 2px 0.5px 2px;
    
    color: map-get($map: $pe_theme, $key: $white);
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    
    display: inline-flex;
    align-items: center;
    
    
    @include media("<md"){
        width: 35px;
    }

}
