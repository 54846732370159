@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss";

.content {
    padding              : 0.5em;
    line-height          : 1.5;
    display              : grid;
    grid-auto-flow       : row;
    row-gap              : 1em;
    column-gap           : 0.7em;
    margin-top           : 0em;
    grid-template-columns: 1fr 1fr;
    overflow-y           : scroll;
}

.valid_discount_value {
    color: $pe_primary;
}

.header_title {
    font-size  : 18px;
    font-weight: 500;
}

.title {
    font-size  : 1.5em;
    font-weight: bold;
}

.c_error {
    color: map-get($map: $pe_theme, $key: text_error);
}

.field {
    display  : flex;
    flex-wrap: wrap;
}

.btn {
    padding         : 0.25em 1em;
    height          : 37px;
    border-radius   : 3px;
    color           : $pe_primary;
    box-shadow      : unset;
    border          : unset;
    background-color: transparent;

    &:disabled {
        background-color: #aaa;
        color           : #333;
    }

    &:hover {
        background-color: $pe_primary;
        color           : map-get($map: $pe_theme, $key: white);
    }
}

.btn_primary {
    background-color: $pe_primary;
    color           : map-get($map: $pe_theme, $key: white);
}

.checkbox_box {
    height         : 100%;
    display        : flex;
    justify-content: flex-start;
    align-items    : flex-end;
}


.actions {
    margin-top     : 2em;
    display        : grid;
    grid-auto-flow : column;
    grid-column    : span 2;
    position       : sticky;
    bottom         : 0px;
    grid-gap       : 0.7em;
    justify-content: flex-end;
}

.date_input {
    width: 100%;
}

.input_field {
    align-content: flex-start;
}

.modal_root {
    width    : 100% !important;
    max-width: unset !important;

    @include media(">sm") {
        width    : 70% !important;
        max-width: var(--pf-c-modal-box--MaxWidth) !important;
    }

    @include media(">md") {
        width: 65% !important;
    }
}