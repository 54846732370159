@import "styles/_variables.scss";
 
.container{
    padding: 1em;
}
.bottomComponent{
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    grid-gap: 20px;
    align-items: center;
}
.btn_primary{
    background-color: map-get($map: $pe_theme, $key: primary) !important;
}