@import "styles/_variables.scss";

    .grid{
        display: grid;
    }
    
        .fullWidth{
            grid-column: 1/-1;
        }
        .field{
            padding-bottom: 10px;
            width: 100%;
        }
        .create_options{
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 5px;
            .item {
                display: grid;
                grid-template-columns: max-content 1fr max-content;
                align-items: center;
                grid-gap: 14px;
            }
            .add_option{
                justify-self: left;
                padding-left: 0px;
            }
        }
        .c_error{
            color: red;
        }
        .NativeSelect{
            button {
                div span {
                    // text-transform: capitalize;
                }
            }
            ul li button{
                // text-transform: capitalize;
            }
        }
    
.switch_primary{
    &:nth-child(0) {
        background-color: map-get($map: $pe_theme, $key: primary) !important;
    }
}
.color_primary{
    color: map-get($map: $pe_theme, $key: text_primary) !important;
}