@import "styles/_variables.scss";

:root {
  --pf-c-button--m-control--active--after--BorderBottomColor    : #{$pe_primary} !important;
  --pf-c-button--m-control--focus--after--BorderBottomColor     : #{$pe_primary} !important;
  --pf-c-button--m-control--hover--after--BorderBottomColor     : #{$pe_primary} !important;
  --pf-c-button--m-control--m-expanded--after--BorderBottomColor: #{$pe_primary} !important;

  --pf-c-button--m-secondary--active--BorderColor: #{$pe_primary} !important;
  --pf-c-button--m-secondary--active--Color      : #{$pe_primary} !important;
  --pf-c-button--m-secondary--BorderColor        : #{$pe_primary} !important;
  --pf-c-button--m-secondary--Color              : #{$pe_primary} !important;
  --pf-c-button--m-secondary--focus--BorderColor : #{$pe_primary} !important;
  --pf-c-button--m-secondary--focus--Color       : #{$pe_primary} !important;
  --pf-c-button--m-secondary--hover--BorderColor : #{$pe_primary} !important;
  --pf-c-button--m-secondary--hover--Color       : #{$pe_primary} !important;

  --pf-c-button--m-link--active--Color             : #{$pe_primary} !important;
  --pf-c-button--m-link--Color                     : #{$pe_primary} !important;
  --pf-c-button--m-link--focus--Color              : #{$pe_primary} !important;
  --pf-c-button--m-link--hover--Color              : #{$pe_primary} !important;
  --pf-c-button--m-link--m-inline--hover--Color    : #{$pe_primary} !important;
  --pf-c-button--m-plain--Color                    : #{$pe_primary} !important;
  --pf-c-button--m-primary--active--BackgroundColor: #{$pe_primary} !important;
  --pf-c-button--m-primary--BackgroundColor        : #{$pe_primary} !important;
  --pf-c-button--m-primary--focus--BackgroundColor : #{$pe_primary} !important;
  --pf-c-button--m-primary--hover--BackgroundColor : #{$pe_primary} !important;
}

.pf-m-primary,
.pf-m-primary:hover {
  --pf-c-button--m-primary--BackgroundColor: #{$pe_primary} !important;
  font-size                                : 14px !important;
}

.pf-c-nav.pf-m-dark {
  --pf-global--active-color--400: #{$pe_primary} !important;
}

.pf-m-current {
  --pf-c-nav__list-link--m-current--after--BackgroundColor: #{$pe_primary} !important;
}


.pf-c-switch__toggle {
  --pf-c-switch__input--checked__toggle--BackgroundColor: #{$pe_primary} !important;
}


.pf-m-link {
  --pf-c-button--m-link--Color: #{$pe_primary} !important;
}


.pf-c-spinner {
  --pf-global--primary-color--100: #000 !important;
}


.pf-c-form-control {
  --pf-c-form-control--focus--BorderBottomColor: #{$pe_primary} !important;
  --pf-global--primary-color--100              : #{$pe_primary} !important;
  --pf-global--link--Color--dark               : #{$pe_primary} !important;
}

.pf-c-select__toggle:before {
  --pf-c-select__toggle--BorderBottomColor: #{$pe_primary} !important;
}

.pf-c-button {
  --pf-global--primary-color--100: #{$pe_primary} !important;
}

.pf-c-button.pf-m-secondary::after {
  --pf-c-button--m-secondary--BorderColor: #{$pe_primary} !important;
  color                                  : #{$pe_primary} !important;
}

.pf-c-button.pf-m-secondary {
  --pf-c-button--m-secondary--Color: #{$pe_primary} !important;
}

.pf-c-table__sort.pf-m-selected .pf-c-button,
.pf-c-table__sort.pf-m-selected .pf-c-table__sort-indicator {
  --pf-c-table__sort--sorted--Color: #{$pe_primary} !important;
}

//#region notification from notitication manager 
.notification {
  background-color: rgba(0, 0, 0, 0.158) !important;
  border-top-color: #{$pe_primary} !important;
}

.pf-c-dropdown__menu {
  // --pf-c-dropdown__menu--BackgroundColor: #151515 !important;
  // background-color                      : #151515 !important;
}

.pf-c-dropdown__menu-item {
  cursor: pointer;
}

.pf-c-table tbody>tr> :first-child::after {
  border-left-color: #{$pe_primary} !important;
}

.pf-c-nav__link.pf-m-current::after,
.pf-c-nav__link.pf-m-current:hover::after,
.pf-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-c-nav__link::after {
  --pf-c-nav__link--m-current--after--BorderColor: #{$pe_primary} !important;

}

.pf-m-grid-md.pf-c-table tbody::after {
  --pf-c-table--tbody--after--BorderColor: #{$pe_primary} !important;
}

.pf-m-expanded>.pf-c-select__toggle::before {
  --pf-c-select__toggle--m-expanded--before--BorderBottomColor: #{$pe_primary} !important;
}

.pf-c-select__toggle::before:hover {
  --pf-c-select__toggle--before--BorderBottomColor: #{$pe_primary} !important;
}