@import "styles/_variables.scss";

.list {
    background-color                : map-get($map: $pe_theme, $key: white);
    .simpleListItem{
        background-color            : map-get($map: $pe_theme, $key: white);
    }
    .item{
        display                     : grid;
        grid-template-columns       : max-content 1fr;
        grid-gap                    : 10px;
        align-items                 : center;
        color                       : map-get($map: $pe_theme, $key: text_primary);;
        .title{
            padding                 : 10px;
        }
    }
}