@import "styles/_variables.scss";

.modal{
    width: 672px;
    max-width: calc(100vw - 32px);
    min-height: 731px;
    
    h1{
        font-family: 'Red Hat Text' !important;
        font-weight: 500 !important;
        color: #151515 !important;
        font-size: 14px !important;
        margin-left: 32px !important;
    }
    div[aria-label=Notifications] button[aria-label=Close] svg{
        color: map-get($map: $pe_theme, $key: black);
    
    }
    .notif_title{
        font-size: 14px;
        font-weight: 500 !important;
        padding-left: 32px;
    }
    
    .icon{
        margin-left: 10%;
    }
    
    ::before{
        content: none !important;
    }
    .accordion_toggle{
        padding-top: 21px;
        background-color: white !important;
        &:hover{
            background-color: white;
        }
        &:hover span{
            font-weight: 400 !important;
            color: #151515 !important;
        }
        &:focus span{
            font-weight: 400 !important;
            color: #151515 !important;
        }
        span{
            font-weight: 400 !important;
            color: #151515 !important;
            font-size: 14px;
        }
    }
    dl dt{
        margin-top: 15px;
    }
    dd>div{
        padding: inherit !important;
    }

    dd>div>table{
        margin-top: 6px !important;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12) !important;
        background-color: $default_white !important;
        &>thead>tr>th{
            font-size: 14px !important;
            font-family: 'Red Hat Text' !important;
            font-weight: 500 !important;
            border-bottom-style: hidden !important;
        }
        &>tbody>tr>td{
            font-size: 14px;
            font-family: 'Red Hat Text';
            font-weight: 400 !important;
        }
    }
    
    
    p{
        font-weight: 500 !important;
        color: #151515;
        padding-left: 32px;
        font-size: 14px;
    }
}

div[aria-label="Notifications"]{
    font-family: 'Red Hat Text' !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
    
@media only screen and (max-width: 576px){
    .modal{
        min-height: unset;
    }
}