@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.wrapper {
    // @include media(">sm") {
    //     width    : 70% !important;
    //     max-width: var(--pf-c-modal-box--MaxWidth) !important;
    // }

    display             : grid;
    align-items         : flex-start;
    justify-content     : center;
    justify-items       : center;
    padding             : 2em 0;
    width               : 100%;
    // background-color : teal;
    background-image    : linear-gradient(to top, rgba(0, 0, 0, 0.541),  rgba(0, 0, 0, 0.541)), url("./../../assets/imgs/background.svg");
    min-height: 100%;
    background-size     : cover, cover;
    background-repeat   : no-repeat no-repeat;


}

.container_loader {
    width          : 100%;
    height         : 100%;
    display        : flex;
    align-items    : center;
    justify-content: center;
}


.container {
    max-width: 544px;
    width    : 100%;
    margin   : 0em auto;
    position : relative;
    padding  : 1em;
    box-shadow: var(--pf-global--BoxShadow--lg);
    margin-top: 1rem;
    border-radius: 2px;
    background-color: white;
}

.candidate_name{
    font-size: 24px;
    margin: 12px auto;
}