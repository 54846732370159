@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.container{
    // @include media(">sm") {
    //     width    : 70% !important;
    //     max-width: var(--pf-c-modal-box--MaxWidth) !important;
    // }

    padding: 8px;

    
}