@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss";

.wrapper {
    margin        : 0px -0.5em;
    padding       : 1em;
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
    box-sizing    : border-box;
    color         : map-get($map: $pe_theme, $key: text_primary);
    font-family   : "Red Hat Text";
    font-size     : 14px;
    font-weight   : 400;
    line-height   : 21px;

    &>* {
        box-sizing         : border-box;
        margin             : 0px 0.5em;
        background-color   : $white;
        // height          : 100%;
        box-shadow         : 0 1px 3px rgba(0, 0, 0, 0.12);
        flex-basis         : 200px;
    }
}

.candidate_forms {
    flex-grow     : 0.3;
    flex-shrink   : 0.3;
    display       : grid;
    grid-auto-flow: row;
    row-gap       : 1em;
    padding-top   : 1em;
    margin-bottom : 1em;
    align-content : flex-start;

    &>* {
        margin: 0px 1em;
    }

    @include media(">sm") {
        margin-bottom: 0px;
    }

}

.main_section {
    flex-grow  : 1;
    flex-shrink: 1;
}

.title {
    font-weight: 450;
}

.heading {
    font-size  : 18px;
    font-weight: 500;
}

.title_row {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding        : 0.7em 1em;
    padding-bottom : 0px;
}

.divider {
    border-bottom: 1px solid map-get($map: $pe_theme, $key: text_primary);
}

.info_field {
    display       : flex;
    align-items   : center;
    flex-direction: row;
    justify-items : flex-start;
}

.grid_columns {
    display       : grid;
    padding       : 1em;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    row-gap       : 0.5em;
}

.icon_add {
    color : $pe_primary;
    height: 20px;
    width : 20px;
}

.flex_column {
    display       : flex;
    width         : 100%;
    height        : 100%;
    flex-direction: row;
    align-items   : center;
    white-space         : pre-wrap;
}

.btn_modify {
    background-color: $pe_primary !important;
    color           : white;
}

.forms_list {
    display          : grid;
    grid-auto-flow   : row;
    margin           : unset;
    // margin-top    : 1em;
}

.form {
    padding              : 0.25em 1em;
    color                : map-get($map: $pe_theme, $key: text_primary);
    display              : grid;
    grid-template-columns: min-content 1fr min-content;
    column-gap           : 0.7em;
    justify-items        : flex-start;
    align-items          : center;
    background-color     : transparent;
    width                : 100%;
}

.current_form {
    background-color: $pe_primary;

    &>* {
        color: white !important;
    }

    color : white !important;
}

.textinput {
    width     : calc(100% - 2em);
    box-sizing: border-box;
}

.bottomSection {
    display       : grid;
    grid-auto-flow: row;
    row-gap       : 1rem;
    justify-items : flex-start;
}

.flex_row {
    display       : flex;
    margin        : -0.5rem;
    flex-direction: row;
    flex-wrap     : wrap;
    margin-top    : 0.25rem;

    &>* {
        margin: 0.5rem;
    }
}

.demand_files {
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
    margin        : -0.5rem;

    &>* {
        text-decoration: underline;
        margin      : 0.25rem;
        // padding-left: 1.5rem;
        position    : relative;

    }
}

.demand_details {
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
    margin        : -0.5rem;

    &>* {
        margin      : 0.25rem;
        padding-left: 1.5rem;
        position    : relative;

        &:before {
            position        : absolute;
            left            : 0.25rem;
            top             : 25%;
            content         : "";
            height          : 10px;
            width           : 10px;
            background-color: $pe_primary;
        }
    }
}

.row{
    display: flex;
    flex-wrap: wrap;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
    margin: -0.5rem;
    &>*{
        margin: 0.5rem;
    }
}

.c_primary{
    color: inherit;
}
