@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.modal_root {
    width    : 100% !important;
    max-width: unset !important;
    bottom: 9em;

    @include media(">sm") {
        width    : 50% !important;
        max-width: var(--pf-c-modal-box--MaxWidth) !important;
    }

    @include media(">md") {
        width: 45% !important;
    }
}

.container {
    display       : grid;
    grid-auto-flow: row;
    row-gap       : 0.5em;
    align-items   : flex-start;
    text-align    : left;
}

.btn_action{
    width: 120px;
}