@import "./../../styles/variables.scss";
@import "~include-media/dist/_include-media.scss";

.container {
    padding: 0.7rem 1rem;

    @include media("<sm") {
        padding: 0;
    }
}

.three_row {
    display       : flex;
    flex-direction:column;
    grid-auto-flow: row;
    justify-items : flex-start;

    &>span {
        &:first-child {
            color      : #151515;
            font-family: "Red Hat Text - Regular";
            font-size  : 16px;
            font-weight: 400;
            line-height: 24px;
            /* Text style for "Bara & Aut" */
            font-family: "Red Hat Text";
            font-weight: 400;
        }

        &:nth-child(2) {
            color      : #151515;
            font-family: "Red Hat Text - Regular";
            font-size  : 14px;
            font-weight: 400;
            line-height: 21px;
            /* Text style for "Matricule" */
            font-family: "Red Hat Text";
            font-weight: 400;
        }

        &:last-child {
            color         : #151515;
            font-family   : "Red Hat Text";
            font-size     : 13px;
            // font-weight: 400;
            // line-height: 16px;
        }

    }
}

.two_row {
    display       : flex;
    flex-direction:column;
    align-content : flex-start;
    grid-auto-flow: row;
    color         : #151515;
    font-family   : "Red Hat Text - Regular";
    font-size     : 14px;
    font-weight   : 400;
    line-height   : 21px;
    /* Text style for "Matricule" */
    font-family   : "Red Hat Text";
    font-weight   : 400;

}

.one_row {
    height        : 100%;
    display       : flex;
    flex-direction:column;
    align-content : flex-end;
    grid-auto-flow: row;
    color         : #151515;
    font-family   : "Red Hat Text - Regular";
    font-size     : 14px;
    font-weight   : 400;
    line-height   : 21px;
    /* Text style for "Matricule" */
    font-family   : "Red Hat Text";
    font-weight   : 400;
}

.btn_new_department {
    // position: relative;
    // top: 1em;
    align-self: flex-end;
}

.btn_delete_company {
    // position: relative;
    // top: 1.2em;
    align-self: flex-end;
}

.table {
    display       : grid;
    grid-auto-flow: row;
    width         : 100%;
    align-items   : stretch;
    align-content : flex-start;
    justify-items : stretch;
}

.table_header {
    display         : flex;
    flex-wrap       : wrap;
    z-index         : 100000000;
    position        : sticky;
    top             : 0;
    padding         : 0.5em;
    position: relative;
    box-sizing      : border-box;
    background-color: white;

    &>* {
        margin: 0.5em;
    }
}
.input_search {
    flex-basis : 300px;
    flex-grow  : 0.3;
    flex-shrink: 0.3;

    @include media("<md") {
        flex-grow  : 0.7;
        flex-shrink: 0.7;
    }
}

.btn_nothing {
@include media("<md") {
    display: none;
}
}
.counter {
    display        : grid;
    grid-auto-flow : column;
    column-gap        : 0.5em;
    align-items    : center;
    justify-self: flex-end;
    position: absolute;
    right: 0;
    justify-content: flex-start;
}

.new_dep_container{
    display: grid;
    grid-auto-flow: row;
    row-gap: 1em;
}

.inline{
display: inline-block;
}
