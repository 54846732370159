@import "styles/_variables.scss";


.form_wrapper{
display: flex;
flex-direction: column;
color: #151515;
font-size: 14px;
font-weight: 400;
}

.custom_form{
    padding: 0px 1em;
}

.header{
display: flex;
align-items: center;
justify-content: space-between;
padding: 1em 1em;
}

.category{
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #15151569;
    margin-bottom: 1em;
}

.footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1em;
    
}

.title{
    color: #151515;
font-family: "Red Hat Text";
font-size: 18px;
font-weight: 500;
}

.actions{
    display: grid;
    grid-auto-flow: column;
    column-gap: 1em;
    align-items: center;
}

.btn{
    padding: 0.4em 0.9em;
    box-shadow: unset;
    border: unset;
    background-color: transparent;
    position: relative;
    border-radius: 0.5em;
    &:hover{
        opacity: 0.7;
    }
    &:active{
        opacity: 1;
    }
}

.btn_flat{
    color: #151515;
    border: 1.5px solid #929090;
}

.btn_primary{
    color: $white;
    background-color: $pe_primary;
    border: 1.5px solid $pe_primary;
}






