@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.container {
    // @include media(">sm") {
    //     width    : 70% !important;
    //     max-width: var(--pf-c-modal-box--MaxWidth) !important;
    // }
    display              : grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap                  : 1rem;
    padding              : 1rem;
    color                : #151515;
    font-family          : "Red Hat Text";
    font-size            : 14px;
    font-weight          : 400;
    justify-content      : center;
}

.item {
    display         : flex;
    flex-direction  : column;
    background-color: white;
    box-shadow      : 0 1px 3px rgba(0, 0, 0, 0.12);
}

.item__header {
    padding              : 12px;
    border-bottom        : 1px solid #ccc;
    display              : grid;
    grid-auto-flow       : column;
    grid-template-columns: 1fr min-content min-content;
    align-items          : center;
}

.item__title {
    color      : #151515;
    font-family: "Red Hat Text";
    font-size  : 14px;
    font-weight: 500;
}

.item__body {
    padding       : 12px;
    display       : flex;
    flex-direction: column;
    padding-bottom: 16px;
}

.row {
    display        : flex;
    justify-content: flex-start;
    align-items    : center;
}

.title {}

.value {
    margin-bottom: 12px;
}


.toolbar {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    margin-top     : 8px;
    flex-wrap      : wrap;
}

.right_section {
    @include media("<sm") {
        margin-top: 16px;
    }
}

.left_section {
    display        : flex;
    flex-direction : row;
    margin         : -0.5em;
    flex-wrap      : wrap;
    box-sizing     : border-box;
    flex-grow      : 1;
    justify-content: flex-start;

    &>* {
        margin: 0.5em;
    }

    .search {
        flex-basis: 450px;
    }

    .dropdown {
        margin: 0.7em;

        .kebabtoggle {
            color           : rgb(115, 118, 121);
            background-color: unset;
        }
    }
}

.btn_nothing {
    @include media("<md") {
        display: none;
    }
}

.loader {
    grid-column    : 1/-1;
    display        : flex;
    height         : 100px;
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.form_container {
    display       : grid;
    grid-auto-flow: row;
    row-gap       : 16px;
    margin-bottom : 100px;
}

.field {
    display       : flex;
    flex-direction: column;
}