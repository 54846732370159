@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.content {
  margin: 1em;
  padding: 1em;
  margin-top: 1em;
  background-color: $white;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.counter {
  padding-top: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.link {
  color: $pe_primary;

  & > * {
    color: $pe_primary;
  }

  align-items: center;
  grid-gap: 1em;
  justify-content: space-between;
  grid-template-columns: max-content min-content;
  grid-template-rows: min-content;
  display: grid;
}

.menu_link:hover {
  color: $pe_primary;
  cursor: pointer;

  & > * {
    color: $pe_primary;
  }
}

.menu_disabled:hover {
  color: rgba(145, 142, 142, 0.836);
  background: #eee;
  cursor: pointer;

  & > * {
    // color: $pe_primary;
  }
}

.toolbar {
  display: flex;
  flex-direction: row;
  margin: -0.5em;
  flex-wrap: wrap;
  box-sizing: border-box;

  & > * {
    margin: 0.5em;
  }

  .search {
    flex-basis: 300px;
    flex-grow: 0.3;
    flex-shrink: 0.3;

    @include media("<md") {
      flex-grow: 0.7;
      flex-shrink: 0.7;
    }
  }
}

.btn_filled {
  background-color: $pe_primary !important;
}

.archived {
  color: map-get($map: $pe_theme, $key: text_warning);
}

.open {
  color: map-get($map: $pe_theme, $key: text_disabled);
}

.locked,
.archived_and_closed {
  color: map-get($map: $pe_theme, $key: text_success);
}

.loading {
  color: $pe_primary;
}

.fax_received {
  color: map-get($map: $pe_theme, $key: text_tetiary);
}

.status_box {
  display: grid;
  font-size: 14px;
  font-weight: 400;
  column-gap: 4px;
  justify-content: flex-start;
  align-items: center;
}

.list {
  display: grid;
  grid-auto-flow: row;
  row-gap: 0.5em;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-top: 1em;
  list-style-type: disc;
}

.btn_nothing {
  @include media("<md") {
    display: none;
  }
}

.status_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  position: relative;
  top: 4px;
}
