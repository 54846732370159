@import "styles/_variables.scss";
 
.form_container{
    ::placeholder {
        font-family: "Red Hat Text";
        font-size: 14px;
        font-weight: 400;
    }
    button{
        background-color: #8481dd !important;
        max-width: 448px;
        width: 96%;
        margin-top: 5px;
    }
    label span:first-child{
        font-size: 16px !important;
        color: #1a1d21 !important;
        line-height: 24px;
        font-weight: 400;
    }
    label:last-child{
        font-size: 16px !important;
        color: #1a1d21 !important;
        line-height: 24px;
        font-weight: 400;
    }
}

.login_page_container{
    main div:last-child p{
        color: #1a1d21 !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
    }
    main header h2{
        color: #151515;
        font-family: "Red Hat Text";
        font-size: 21px !important;
        font-weight: 500 !important;
        line-height: 31.2px;
        margin-bottom: 10px;
    }
    main header p{
        color: #1a1d21;
        font-family: "Red Hat Text";
        font-size: 14px;
        font-weight: 400 !important;
        line-height: 24px;
        margin-bottom: 45px;
    }
    footer ul{
        padding: 0; 
    }
    footer ul li{
        font-size: 14px !important;
        display:inline;
        margin: 0 23px 0 0;
    }
    footer ul li a{
        color: #ffffff !important;
    }
    // main div:last-child button{
    //     padding-top: 25px;
    //     background-color: red !important;
    // }
}