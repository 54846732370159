@import "styles/_variables.scss";

.container{
    
}
.btn_primary{
    background-color: map-get($map: $pe_theme, $key: primary) !important;
}
.color_primary{
    color: map-get($map: $pe_theme, $key: text_primary) !important;
}

.content {
    min-height: 250px;
}

.theme{
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
}