@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.wrapper {
  padding: 1em;
}

.content {
  background-color: $white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > * {
    align-self: flex-start;
    flex-basis: 250px;
    padding: 1em;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.first_section {
  border-right: 1px solid rgba($color: #000000, $alpha: 0.2);
  display: grid;
  grid-auto-flow: row;
  align-content: space-between;
}

.form {
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
}

.title {
  color: #151515;
  font-family: "Red Hat Text";
  font-size: 18px;
  font-weight: 500;
  line-height: 31.2px;
}

.row {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 0px;

  @include media(">sm") {
    grid-auto-flow: row;
    grid-template-columns: unset;
  }
}

.verifiabl_section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.count_input{
    width: 50px;
    margin-left: 1.5rem;
  }

.formfield {
  display: grid;
  grid-auto-flow: row;

  & > span {
    text-align: left;
    justify-self: flex-start;
  }
}

.fullWidth {
  grid-column: 1/-1;
}

.actions {
  display: grid;
  gap: 1em;
  justify-content: flex-end;
  margin: 2em 0 1em;
  grid-auto-flow: column;
}

.btn_submit {
  background-color: $pe_primary;
}

.second_section {
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
}

.requested_infos {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5em;
  justify-items: flex-start;
  margin-bottom: 1em;
}

.file_field {
  display: flex;
  align-items: center;
  & > * {
    flex-basis: 180px;
  }
}

.btn_delete {
  margin-left: 1em;
}

.cover {
  display: flex;
  margin-left: 1em;
  flex-grow: 0.5;
  flex-shrink: 0.5;
  align-items: center;
}

.input_file {
  display: none;
}

.c_error {
  color: map-get($map: $pe_theme, $key: text_error);
}

.input_count {
  width: 40px;
}
