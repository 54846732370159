@import "styles/_variables.scss";

.wrapper {
  display           : grid;
  grid-template-rows: 1fr;
  height            : 100%;
  width             : 100%;
}

.notify_class {
  color    : $pe_primary !important;
  animation: pulse 2s infinite;
}

.counter {
  background-color: white;
  height          : 20px;
  width           : 20px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  font-weight     : bold;
  border-radius   : 50px;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(0.95);
  }
}

.section {
  padding: unset !important;
}

.lksdflkj {
  &>*:hover {
    // color : unse !important;
    color: map-get($map: $pe_theme, $key: text_primary) !important;
  }
}