html,
body,
#root {
  height : 100%;
  width  : 100%;
  margin : unset;
  padding: unset;
}

.btn_icon {
  box-shadow           : unset;
  background-color     : transparent;
  border               : unset;
  padding              : 0.25em;
  border-radius        : 0.25em;
  // &:hover{
  //   background-color: #eee;
  //   border:1px solid #1a1d21;
  //   color: #1a1d21 !important;
  // }
}


/* Styling scrollbars */
.bars {
  scrollbar-width: thin;
  scrollbar-color: #888;
}

/* width */
::-webkit-scrollbar {
  height: 8px;
  width : 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background   : #ffffff00;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background   : #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background   : #555;
  border-radius: 12px;
}
//stripe styles
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
 .StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}