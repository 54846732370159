@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss";

.content {
    display       : grid;
    grid-auto-flow: row;
    row-gap       : 1em;
    align-items   : center;
    justify-items : flex-start;
    align-content : flex-start;
    color         : #151515;
    font-family   : "Red Hat Text";
    font-size     : 14px;
    font-weight   : 400;
    line-height   : 21px;
}


.modal_root {
    width    : 100% !important;
    max-width: unset !important;

    @include media(">sm") {
        width    : 70% !important;
        max-width: var(--pf-c-modal-box--MaxWidth) !important;
    }

    @include media(">md") {
        width: 65% !important;
    }
}

.title {
    color      : #151515;
    font-family: "Red Hat Text";
    font-size  : 14px;
    font-weight: 500;
    line-height: 21px;
}

.grid_row {
    display              : grid;
    column-gap           : 0.5em;
    grid-template-columns: auto auto;
}

.grid_column {
    display       : grid;
    grid-auto-flow: row;
}

.file {
    display: flex;
    align-items: center;

    &>* {
        margin-right: 1em;
    }
}

.btn_download {
    background-color: transparent;
    box-shadow      : unset;
    border          : unset;
    color           : $pe_primary;
    border-radius   : 2px;
    padding         : 0.5em 1em;


    &:hover {
        background-color: rgba($color: #000000, $alpha: 0.2);
    }
}

.input {
    width: 200px;
}

.btn_download_pdf {
    background-color: $pe_primary !important;
    margin-top      : 1em;
}