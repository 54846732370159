$link-hover-color             : currentColor;
$link-hover-decoration        : none;
$label-margin-bottom          : 0;
$label-margin-bottom          : 0;
$enable-responsive-font-sizes :true;
$pe_primary                   :#8481dd;
$link-color                   : currentColor;
$default_background           :white;
$white           :white;
$default_white                :#f5f5f5;


//Material design breakpoints
$breakpoints: (
    xs: 0, // handset portrait (small, medium, large) | handset landscape (small)
    sm           : 37.5em, // handset landscape (medium, large) | tablet portrait(small, large)
    md           : 60em, //  tablet landscape (small, large)
    lg           : 80em, // laptops and desktops
    xl           : 100em // large desktops
);

$container-max-widths: (sm: 37.5em,
    md: 60em,
    lg: 80em,
    xl: 100em);

// Font-Size
$base-font-size: 1rem;

// Line-Height
$base-line-height  : 1.5;
$header-line-height: 1.25;


$pe_warning : (default:$pe_primary,
    success:#51a548,
    warning:#fc952e,
    error:#df3c3f,
);


$pe_theme : (black:#1a1d21,
    success:#51a548,
    text_success:#51a548,
    success_light:rgba(81, 165, 72, 0.75),
    warning:#fc952e,
    text_warning:#fc952e,
    warning_light: rgba(240, 172, 0, 0.75),
    text_primary:#151515,
    error:#df3c3f,
    text_error:#df3c3f,
    error_light:rgba(223, 60, 63, 0.75),
    body_background: #ededed,
    white:#fff,
    primary:#8481dd,
    text_link:$pe_primary,
    text_disabled:#d2d2d2,
    text_tetiary:#dd81b2,
    gray_clear:#d2d2d2,
);

@mixin bars() {
    scrollbar-width: thin;
    scrollbar-color: #888;
}