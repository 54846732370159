@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.wrapper {
  // @include media(">sm") {
  //     width    : 70% !important;
  //     max-width: var(--pf-c-modal-box--MaxWidth) !important;
  // }
}

.modal_root {
  width    : 100% !important;
  max-width: unset !important;

  @include media(">sm") {
      width    : 60% !important;
      max-width: var(--pf-c-modal-box--MaxWidth) !important;
  }

  @include media(">md") {
      width: 40% !important;
  }
}



.container {
  display: grid;
  grid-auto-flow: row;
  row-gap: 8px;
  justify-items: flex-start;
  align-items: left;
}

.column {
  display: grid;
  grid-auto-flow: row;
  row-gap: 8px;
}
.input {
  margin-left: 4em;
  width: 50px;
}

.footer {
  // justify-self  : flex-end;
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  width: 100%;
  grid-template-columns: 150px 150px;
  justify-content: flex-start;
}
