@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities
    
.wrapper {
    height         : 100%;
    width          : 100%;
    grid-column    : 1/-1;
    display        : flex;
    align-items    : center;
    justify-content: center;
}