@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.content {
    padding              : 0.5em;
    line-height          : 1.5;
    padding-left         : unset;
}

.header_title {
    font-size  : 18px;
    font-weight: 500;
}

.group{
    font-size  : 14px;
    font-weight: 500;
    line-height: 21px;
}

.field {
    font-size: 14px;
    input{
        font-size: 14px;
    }
    @include media(">md") {
        width: 89%;
    }
}

.c_error {
    color: map-get($map: $pe_theme, $key: text_error);
}

.btn {
    padding         : 0.25em 1em;
    height          : 37px;
    border-radius   : 3px;
    color           : $pe_primary;
    box-shadow      : unset;
    border          : unset;
    background-color: transparent;

    &:disabled {
        background-color: #aaa;
        color           : #333;
    }

    &:hover {
        background-color: $pe_primary;
        color           : map-get($map: $pe_theme, $key: white);
    }
}

.btn_primary {
    background-color: $pe_primary;
    color           : map-get($map: $pe_theme, $key: white);
}

.radio div{
    position       : relative;
    display        : flex;
    align-items    : center;
    align-content  : center;
    justify-content: space-between;
    padding        : 0px;
    max-width      : 130px;
    label{
        font-size: 14px;
    }
    input{
        width : 16px;
        height: 16px;
    }
}


.actions {
    margin-top     : 2em;
    display        : grid;
    grid-auto-flow : column;
    position       : sticky;
    bottom         : 0px;
    grid-gap       : 0.7em;
    justify-content: flex-end;
}

.modal_root {

    @include media(">sm") {
        width: 40% !important;
    }
    
    @include media(">md") {
        width: 40% !important;
    }
}