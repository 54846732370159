@import "styles/_variables.scss";
@import "styles/vars.scss";

.wrapper {
    display       : flex;
    flex-direction: column;
    overflow-y    : auto;
    overflow-x    : hidden;
}

.content {
    margin          : 1em;
    padding         : 1em;
    margin-top      : 2em;
    background-color: $white;
    border-radius   : 4px;
    box-sizing      : border-box;
    box-shadow      : 0 1px 3px rgba(0, 0, 0, 0.12);
}

.counter {
    padding-top    : 0.5em;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
}

.link {
    color: $pe_primary;

    &>* {
        color: $pe_primary;
    }

    align-items          : center;
    grid-gap             : 1em;
    justify-content      : space-between;
    grid-template-columns: max-content min-content;
    grid-template-rows   : min-content;
    display              : grid;
    cursor               : pointer;
}

.toolbar {
    display       : flex;
    flex-direction: row;
    margin        : -0.5em;
    flex-wrap     : wrap;
    box-sizing    : border-box;

    &>* {
        margin: 0.5em;
    }

    .search {
        flex-basis: 250px;
    }

    .selections {
        align-self: center;
    }
}

.btn_filled {

    // background-color: $pe_primary !important;
    &:disabled {
        background-color: #eee !important;
    }
}

.archived {
    color: map-get($map: $pe_theme, $key: text_warning);
}

.open {
    color: map-get($map: $pe_theme, $key: text_disabled);
}

.locked, .archived_and_closed {
    color: map-get($map: $pe_theme, $key: text_success);
}

.loading {
    color: $pe_primary;
}

.fax_received {
    color: map-get($map: $pe_theme, $key: text_tetiary);
}

.status_box {
    display        : grid;
    grid-auto-flow: column;
    font-size      : 14px;
    font-weight    : 400;
    justify-content: flex-start;
    align-items    : center;
}