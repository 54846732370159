@import "styles/_variables.scss";

.container {
    margin          : 1em;
    padding         : 1em;
    background-color: $white;
    border-radius   : 4px;
    box-sizing      : border-box;
    box-shadow      : 0 1px 3px rgba(0, 0, 0, 0.12);

    .form {
        max-width: 609px;

        .text_input {
            font-weight: 400;
            font-size  : 14px;

        }

        .text_input input {
            padding-bottom: unset;
            padding-top   : unset;
        }
    }

    .group {
        font-size    : 18px;
        font-weight  : 500;
        line-height  : 31.2px;
        margin-bottom: -15px;
    }

    .avatar_container {
        width           : 86px;
        height          : 86px;
        border-radius   : 43px;
        background-color: map-get($map: $pe_theme, $key: primary);
        text-align      : center;
        transition      : .5s ease;
        opacity         : 1;
        text-align      : center;
        position        : relative;
        cursor          : pointer;
    }

    .label {
        width : 86px;
        height: 86px;
    }

    .avatar_container:hover .avatar_text {
        opacity  : 1;
        transform: translateY(0px);
    }

    .avatar_text {
        opacity         : 0;
        color           : white;
        font-family     : "Red Hat Text";
        font-size       : 14px;
        font-weight     : 400;
        line-height     : 21px;
        top             : 0;
        position        : absolute;
        width           : 100%;
        height          : 100%;
        border-radius   : 50%;
        background-color: rgba(0, 0, 0, 0.12);
        display         : flex;
        align-items     : center;
        transition      : all 200ms;
        // opacity: 1;
    }

    .actions {
        margin-top     : 2em;
        display        : grid;
        bottom         : 0px;
        grid-gap       : 0.7em;
        justify-content: flex-end;
    }

    .btn {
        padding         : 0.25em 1em;
        height          : 37px;
        border-radius   : 3px;
        color           : black;
        box-shadow      : unset;
        border          : unset;
        background-color: transparent;

        &:disabled {
            background-color: #aaa;
            color           : #333;
        }

        &:hover {
            border          : unset;
            background-color: $pe_primary;
            color           : map-get($map: $pe_theme, $key: white);
        }
    }

    .btn_secondary {
        height: 36px;
        border: 2px solid #aaa;
    }

    .btn_primary {
        background-color: $pe_primary;
        color           : map-get($map: $pe_theme, $key: white);
    }
}

.image_container {
    height         : 100%;
    width          : 100%;
    display        : grid;
    justify-content: stretch;
    align-items    : stretch;
    border-radius  : 100px;
    overflow       : hidden;
}