@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.container {
    padding       : 1em;
    display       : grid;
    gap           : 1em;
    grid-auto-rows: min-content;

}

.card {
    box-shadow      : 0 1px 3px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    padding         : 0.7em;
}

.card__fullwidth {
    grid-column: 1/-1;
}

.card__title {
    font-size: 14px;
}

.card__main_text {
    font-size: 21px;
}

.card__table {
    // min-height: 238px;
}

.section {
    display       : grid;
    grid-auto-flow: row;
}

.btn_new {
    color: #8481dd !important;
}

.table_container {}

.table_header {
    display              : grid;
    gap                  : 1em;
    margin-bottom        : 0.5em;
    justify-items        : flex-start;
    grid-template-columns: repeat(auto-fit, 250px);
}

.input_search {
    width: 100%;
}

.btn_new_dep {
    justify-self: flex-start;
}

.counter {
    display        : grid;
    justify-content: flex-end;
    grid-auto-flow : column;
    gap            : 1rem;
}