@import "~@patternfly/react-core/dist/styles/base.css";
@import "styles/variables.scss";
@import "styles/vars.scss";
@import "~include-media/dist/_include-media.scss";


html,
body {
    height: 100%;
}

body {
    margin     : 0;
    font-family: 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
}

.btn {
    box-shadow      : unset;
    border          : unset;
    background-color: transparent;
    display         : flex;
    align-items     : center;
    justify-content : center;
    border-radius   : 4px;
    padding         : 0.5em;
}

.btn-icon {
    display              : grid;
    grid-template-columns: 24px;
    grid-auto-flow       : column;
    grid-auto-columns    : auto;
}

.btn-flat {
    transition: all 0.3s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        background-color: rgba($color: #000000, $alpha: 0.2);
    }

    &:disabled {
        background-color: rgba($color: #000000, $alpha: 0.01);
        color           : rgba($color: #000000, $alpha: 0.2);
    }
}

.telephone_field{
    display: grid;
    grid-auto-flow:column;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    align-items: stretch;
    width: 100%;
    height: 100%;
    flex-grow: 100%;
}

// .padding{
//     padding: 1em 0 !important;
// }


.table__no-border tr {
    border: unset !important;
}