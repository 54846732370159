@import "styles/_variables.scss";

.header {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    align-items: center;
    h1{
        /* Style for "Ville de L" */
        color: #1a1d21;
        font-size: 16px;
        font-weight: 600;
    }
    .checkbox{
        input {
            border: none;
            padding: 5px;
            background-color: $default_white;
        }
    }
    .btn_delete{
        color: map-get($map: $pe_theme, $key: black);
    }
}
.description{
    p {
        padding-bottom: 20px;
    }
}
.border_bottom{
    border-bottom: 1px solid map-get($map: $pe_theme, $key: gray_clear);
}