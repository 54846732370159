@import "styles/_variables.scss";

.accordion {
    background-color   : map-get($map: $pe_theme, $key: white);
    padding            : 1em;
    padding-top        : unset;
    text-align         : left;
    // min-height      : 120px;
    width              : 100%;
    position           : relative;
    display            : grid;
    grid-auto-flow     : row;
    align-items        : flex-start;
    align-content      : flex-start;
    font-size          : 16px;
    font-weight        : 400;
    line-height        : 24px;
    height             : min-content;
}

.shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);  
}

.accordion_title {
    font-size  : 21px;
    font-weight: 400;
    line-height: 31.2px;
}

.crumbs_container {
    display          : grid;
    grid-auto-flow   : column;
    column-gap       : 8px;
    align-items      : center;
    justify-content  : flex-start;
    justify-items    : flex-start;
    grid-auto-columns: auto;
    margin-bottom    : 1em;
    color            : $pe_primary;
}

.active_route_crumb {
    color      : map-get($map: $pe_theme, $key: text_primary) !important;
    font-weight: 500;

}

.icon_chevron {
    color: map-get($map: $pe_theme, $key: text_disabled);
}