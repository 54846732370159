@import "styles/_variables.scss";
 
.container{
    padding: 1em;
}

.bottomComponent {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    justify-items : center;
    flex-wrap     : wrap;

    &>* {
        margin-right: 1em;
    }

    &>input {
        width: 250px;
    }
}

.btn_primary{
    background-color: map-get($map: $pe_theme, $key: primary) !important;
}