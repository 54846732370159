@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.content {
    padding              : 0.5em;
    line-height          : 1.5;
    display              : grid;
    grid-auto-flow       : row;
    row-gap              : 1em;
    column-gap           : 0.7em;
    margin-top           : 0em;
    grid-template-columns: 1fr 1fr;


    @include media("<xs") {
        display: contents;
    }
}

.header_title {
    font-size  : 18px;
    font-weight: 500;
}

.list{
    &>*{
        margin: 0.75rem 0;
    }
}


.btn {
    font-size  : 14px;
    padding         : 0.25em 0.75em;
    height          : 37px;
    border-radius   : 3px;
    color           : $pe_primary;
    box-shadow      : unset;
    border          : unset;
    background-color: transparent;

    &:disabled {
        background-color: #aaa;
        color           : #333;
    }

    &:hover {
        background-color: $pe_primary;
        color           : map-get($map: $pe_theme, $key: white);
    }
}

.btn_primary {
    background-color: $pe_primary;
    color           : map-get($map: $pe_theme, $key: white);
}


.actions {
    margin-top     : 2em;
    display        : grid;
    grid-auto-flow : column;
    grid-column    : span 2;
    bottom         : 0px;
    grid-gap       : 0.7em;
    justify-content: flex-end;
}

.modal_root {
    @include media(">sm") {
        width    : 70% !important;
        max-width: var(--pf-c-modal-box--MaxWidth) !important;
    }

    @include media(">md") {
        width: 50% !important;
    }
}

.group{
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}