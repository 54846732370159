@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.notification_container_ref {
    position: fixed;
    top  : 1em;
    right   : 1em;
    z-index : 9999;
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 8px;
}