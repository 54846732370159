@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.modal_root {
    width    : 100% !important;
    max-width: unset !important;
    bottom   : 9em;

    @include media(">sm") {
        width    : 50% !important;
        max-width: var(--pf-c-modal-box--MaxWidth) !important;
    }

    @include media(">md") {
        width: 45% !important;
    }
}

.search {
    max-width: 250px;
    margin-bottom: 1rem;
}

.form{
    min-height: 300px;
}

.label {
    display       : grid;
    grid-auto-flow: row;

}