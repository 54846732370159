@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.modal_root {
    width    : 100% !important;
    max-width: unset !important;
    bottom   : 9em;

    @include media(">sm") {
        width    : 80% !important;
        max-width: var(--pf-c-modal-box--MaxWidth) !important;
    }

    @include media(">md") {
        width: 65% !important;
    }
}

.footer {
    // justify-self  : flex-end;
    display       : grid;
    grid-auto-flow: column;
    column-gap    : 1rem;
    width: 100%;
    grid-template-columns: 150px 150px;
    justify-content: flex-end;
}