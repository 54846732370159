@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.modal_root {
    width    : 100% !important;
    max-width: unset !important;

    @include media(">sm") {
        width    : 70% !important;
        max-width: var(--pf-c-modal-box--MaxWidth) !important;
    }

    @include media(">md") {
        width: 65% !important;
    }
}

.container {
    min-height: 500px;
}

.dragable_item {
    border       : 1px solid #151515;
    border-radius: 0.5em;
}