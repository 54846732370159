@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.container {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 3em 3em 3em;
  float: none !important;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1.5em 0;
}

.logo {
  opacity: 0.5;
  width: 160px;
  height: 70px;
}

.header_content {
  opacity: 0.5;
  color: #333333;
  display: grid;
  grid-auto-flow: row;
  row-gap: 4px;
  align-items: center;
  text-align: right;
}

.underline {
  border: 1px solid #7070707c;
  border-radius: 4px;
  // height       : 1px;
  width: 100%;
}

.section {
  margin-top: 1em;
  display: block;
}

.title {
  display: grid;
}

.grid_column {
  display: block;
  grid-auto-flow: row;
  row-gap: 2px;
  align-items: flex-start;

  & > *:not(last-child) {
    margin-bottom: 4px;
  }
}

.grid_row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-items: flex-start;
  white-space: pre-wrap;
}

.form_name {
  margin-bottom: 0.3em;
  position: relative;
  color: #5752d1;
  font-family: Arial;
  font-size: 24px;
  font-weight: 700;
  justify-self: flex-start;
  // line-height  : 61.87px;

  &::after {
    content: "";
    height: 2px;
    bottom: 0px;
    width: 100%;
    position: absolute;
    left: 0px;
    background-color: $pe_primary;
  }
}

.cat_name {
}

.bold {
  font-size: 14px;
  font-weight: 700;
}

.pdf_container {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  margin-bottom: 1em !important;
}

.pdf_container {
  border: 1px solid black;
  padding: 4px;
  // page-break-after : auto !important;
  // page-break-before: always !important;
  page-break-inside: avoid !important;
  white-space: pre-wrap;

  th,
  td {
    border: 1px solid black;
    padding: 4px;
  }
}

.first_page {
  min-height: 100vh;
  width: 100%;
  justify-items: center;
  margin: 0px auto;
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-gap: 1em;
  display: none;
  page-break-after: auto !important;
  page-break-before: always !important;
  page-break-inside: avoid !important;

  svg {
    // height: 400px;
  }
}

.standard_page {
  display: grid;
  page-break-inside: unset !important;
}

.first_page_text {
  color: #333333;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 3em;
}

.document_stack {
  height: 200px;
  margin-bottom: 2em;
}

.front_logo {
  opacity: 0.5;
  height: 300px;
  margin-top: 2em;
}

.standard_content {
  // display       : grid;
  // grid-auto-flow: row;
  // justify-items : flex-start;
}

.checked {
  margin: 0px auto;
}

.center_cell {
  text-align: center;
}

.inner_table {
  padding: 0px 8px;

  & > tr {
    page-break-after: auto !important;
    // page-break-before: always !important;
    page-break-inside: avoid !important;
  }

  & > tr:first-child {
    background-color: #d6d6d6;
  }
}

.legends {
  margin: 0.5em 4px;
  display: grid;
  grid-auto-flow: row;
  justify-items: flex-start;
  grid-gap: 4px;
}

@media print {
  .first_page {
    display: grid;
  }

  @page {
    margin: 2em;
    width: 100%;
    // padding: 0;
  }
}

@media print {
  .pdf_container {
    page-break-inside: auto;
  }

  .tr {
    gap: 5em;
    page-break-after: auto !important;
    page-break-before: always !important;
    page-break-inside: avoid !important;
  }
}

.btn_download {
  position: fixed;
  bottom: 0.5em;
  right: 2em;
}

.btn_reorder {
  position: fixed;
  bottom: 0.5em;
  right: 13.5em;
}

@media print {
  .btn_download {
    display: none;
  }
  .btn_reorder {
    display: none;
  }
}
