@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.container {
    @include media("<sm") {
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }

    padding              : 1em;
    display              : grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap                  : 1em;
    grid-auto-rows       : min-content;


}

.card {
    box-shadow      : 0 1px 3px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    padding         : 0.7em;
}

.card__fullwidth {
    grid-column: 1/-1;
}

.card__title {
    font-size: 14px;
}

.card__main_text {
    font-size: 21px;
}

.card__illustration {
    display       : grid;
    grid-auto-flow: row;
    row-gap       : 0.7em;
    align-self    : flex-start;
    justify-items : flex-start;
    align-content : flex-start;
}

.card__graph {
    min-height    : 238px;
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
}

.pie {
    flex-basis : 250px;
    flex-grow  : 0.5;
    flex-shrink: 0.5;
}

.line {
    flex-basis : 500px;
    flex-grow  : 1;
    flex-shrink: 1;
}

.vertical_seperator:first-child {
    // width       : 1px;
    height      : 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.171);
    margin-right: 1em;
}

.card__table {
    // min-height: 238px;
}

.section {
    display          : grid;
    grid-auto-flow   : row;
    // row-gap       : 0.2em;
}

.pie_labels {
    display       : grid;
    grid-auto-flow: column;
    column-gap    : 0.5em;
    font-size     : 12px;
    align-items   : center;
    justify-items : flex-start;
}

.pie_graph {
    justify-self: stretch;
}

.line_graph {
    justify-self: stretch;

    tspan {
        font-size: 0.4rem !important;
        @include media('<md'){
            font-size: 0.7rem !important;
        }
    }

    &>div {
        display      : flex;
        justify-items: stretch;
    }
}

@include media("<md") {
    .pie {
        margin-bottom: 0.7em;
    }

    .line {
        margin-bottom: 0.7em;
    }

    .vertical_seperator {
        border-right: unset;
    }
}

.btn_new {
    color: #8481dd !important;
}

.table_container {}

.table_header {
    display              : grid;
    gap                  : 1em;
    margin-bottom        : 0.5em;
    justify-items        : flex-start;
    grid-template-columns: repeat(auto-fit, 250px);
}

.input_search {
    width: 100%;
}

.btn_new_dep {
    justify-self: flex-start;
}