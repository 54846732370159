@import "styles/_variables.scss";
@import "node_modules/include-media/dist/_include-media.scss"; // add css utilities

.container {
    padding                 : 1em;
   // display                 : grid;
   // grid-template-columns   : repeat(auto-fill, minmax(170px, 1fr));
   // gap                     : 1em;
   // grid-template-rows   : min-content;

    @include media("<sm") {
      //  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }


}

.toolbar{
    display: grid;
    
}

.fullwidth{
    grid-column: 1/-1;
    padding: 0;
}

.graph_section {
    grid-row: span 2;
}

.users_illustration {
    grid-row: span 1;
}

.card__title {
    font-size: 14px;
    font-weight: 500;
}

.card__main_text {
    font-size: 21px;
}

.card__illustration {
    display       : grid;
    grid-auto-flow: row;
    row-gap       : 0.7em;
    align-self    : flex-start;
    justify-items : flex-start;
    align-content : flex-start;
}

.card__graph {
    min-height: 238px;
    display   : flex;
    flex-wrap : wrap;
    align-self: flex-start;
}

.pie {
    flex-basis : 300px;
    flex-grow  : 0.5;
    flex-shrink: 0.5;

    @include media("<md") {
        flex-basis: unset;
        width     : 100%;
    }
}

.pie_container {
    display      : flex;
    flex-wrap    : wrap;
    margin-bottom: 1em;
}

.line {
    flex-basis : 500px;
    flex-grow  : 1;
    flex-shrink: 1;
}

.vertical_seperator {}

.card__table {
    // min-height: 238px;
}

.section {
    display          : grid;
    grid-auto-flow   : row;
    background-color : #f5f5f5;
    padding          : 10px;
}

.textIcon{
    display: grid;
    grid-template-columns: max-content 1fr;
}

.pie_labels {
    display       : grid;
    grid-auto-flow: column;
    column-gap    : 0.5em;
    font-size     : 12px;
    align-items   : center;
    justify-items : flex-start;
}

.pie_graph {
    justify-self: stretch;
}

.line_graph {
    justify-self: stretch;

    tspan {
        font-size: 0.4rem !important;

        @include media('<md') {
            font-size: 0.7rem !important;
        }
    }

    &>div {
        display      : flex;
        justify-items: stretch;
    }
}

@include media("<md") {
    .pie {
        margin-bottom: 0.7em;
    }

    .line {
        margin-bottom: 0.7em;
    }

    .vertical_seperator {
        border-right: unset;
    }
}

.btn_new {
    color: #8481dd !important;
}

.table_container {}

.table_header {
    display              : grid;
    gap                  : 1em;
    margin-bottom        : 0.5em;
    justify-items        : flex-start;
    grid-template-columns: repeat(auto-fit, 250px);
}

.input_search {
    width: 100%;
}

.btn_new_dep {
    justify-self: flex-start;
}

.grid_column {
    display              : grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows       : min-content;
    grid-gap             : 10px;
    .flexEnd{
        justify-self     : flex-end;
    }
}
.textCenter{
    text-align: center;
}

.row {
    display        : flex;
    align-items    : center;
    flex-direction : row;
    justify-content: flex-start;
}

.center_justify {
    justify-self: center;
}