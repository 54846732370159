@import "styles/_variables.scss";

.dropdown{
    font-size: 14px;
    color: map-get($map: $pe_theme, $key: text_primary);
    max-width: 279px !important;
    height: 40px;
    font-weight: 400;
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    &>img{
        width: 16px;
        margin-right: 8px;
    }
}

@media only screen and (max-width: 576px){
    .dropdown{
        max-width: 240px !important;
    }
}

.dropdown_total{
    color: #6a6e73;
    font-weight: 400;
}

.dropdown_item_text{
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    display: block;
    flex: 1;
}

.dropdown_item_text:hover {
    font-weight: 500 !important;
}

.number{
    width: 26px;
    height: 18px;
    background-color: #d9241c;
    margin-left: 5px;

    text-align: center;
    color: map-get($map: $pe_theme, $key: $white);
    font-size: 12px;
    font-weight: 400;
}
